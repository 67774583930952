<script lang="ts" setup>
import {FooterResource} from "~/resources/PageResource";

const {t} = useI18n()
const localePath = useLocalePath()
const trustbox = ref(null)

const isAuthModalOpen = ref(false)

const props = defineProps<{
  content: FooterResource
}>()

onMounted(() => {
  setTimeout(() => {
    if (trustbox) {
      const tpScript = document.createElement('script')
      tpScript.type = 'text/javascript'
      tpScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      tpScript.async = true
      document.head.appendChild(tpScript);

      tpScript.onload = () => window.Trustpilot.loadFromElement(trustbox);
    }
  }, 1000)
})
</script>

<template>
  <footer class="container">
    <section>
      <address>
        <h2>Work<span class="green">Snap</span></h2>
        <p>
          {{ content.slogan }}
        </p>
        <ul>
          <!--          <li>-->
          <!--            <div class="icon">-->
          <!--              <i class="fas fa-location-dot"></i>-->
          <!--            </div>-->
          <!--            <NuxtLink to="https://goo.gl/maps/krxVtS2L1PvQ1pGT8" :external="true" target="_blank">Max Heymansstraat 81, 3059MK Rotterdam</NuxtLink>-->
          <!--          </li>-->
          <li>
            <div class="icon">
              <i class="fas fa-at"></i>
            </div>
            <NuxtLink to="mailto: info@worksnap.net?subject=Mail from out website" :external="true" target="_blank">
              info@worksnap.net
            </NuxtLink>
          </li>
          <!--          <li>-->
          <!--            <div class="icon">-->
          <!--              <i class="fas fa-phone"></i>-->
          <!--            </div>-->
          <!--            <NuxtLink to="tel:+31612345678" :external="true" target="_blank">+31 6 1234 5678</NuxtLink>-->
          <!--          </li>-->
        </ul>
      </address>
      <nav aria-labelledby="discover-nav">
        <h3 id="discover-nav">{{ $t('navigation.discover') }}</h3>
        <ul>
          <li>
            <NuxtLink :to="localePath('/spaces')">{{ $t('navigation.spaces') }}</NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/contact')">{{ $t('navigation.contact') }}</NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/about-us')">{{ $t('navigation.about') }}</NuxtLink>
          </li>
        </ul>
      </nav>
      <nav aria-labelledby="popular-nav">
        <h3 id="popular-nav">{{ $t('navigation.popular') }}</h3>
        <ul>
          <li>
            <NuxtLink :to="`${localePath('/spaces')}?address=Amsterdam,+Netherlands`">{{ $t('navigation.amsterdam') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="`${localePath('/spaces')}?address=Rotterdam,+Netherlands`">{{ $t('navigation.rotterdam') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="`${localePath('/spaces')}?address=Utrecht,+Netherlands`">{{ $t('navigation.utrecht') }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <nav aria-labelledby="login-nav">
        <h3 id="login-nav">Operators</h3>
        <ul>
          <li>
            <a href="#" @click="isAuthModalOpen = true">
              {{ t('navigation.login') }}
            </a>
          </li>
        </ul>
      </nav>
    </section>
    <nav aria-label="Partners" class="partners">
      <ul>
        <li>
          <nuxt-img alt="Campus Offices Logo" title="Campus Offices Logo" src="/logos/campus-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="Element Offices Logo" title="Element Offices Logo" src="/logos/element-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="Of Us Logo" title="Of Us Logo" src="/logos/ofus-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="De Stadstuin Logo" title="De Stadstuin Logo" src="/logos/stadstuin-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="Tribes Logo" title="Tribes Logo" src="/logos/tribes-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="De Werkloft Logo" title="De Werkloft Logo" src="/logos/werkloft-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="People@Places Logo" title="People@Places Logo" src="/logos/peopleplaces-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="Placesforbizz Logo" title="Placesforbizz Logo"
                    src="/logos/placesforbizz-removebg-preview.png"/>
        </li>
        <li>
          <nuxt-img alt="PRI Office Logo" title="PRI Office Logo" src="/logos/prioffice-removebg-preview.png"/>
        </li>
      </ul>
    </nav>
    <nav aria-label="Secondary">
      <i18n-t keypath="footer.redwood" tag="p" scope="global">
        <template v-slot:worksnap>
          <strong>WorkSnap</strong>
        </template>
        <template v-slot:link>
          <a href="https://redwoodcapital.nl" target="_blank" title="Redwood Capital BV">Redwood Capital BV</a>
        </template>
      </i18n-t>
      <NuxtLink :to="localePath('/privacy-policy')">{{ $t('footer.privacy') }}</NuxtLink>
      <NuxtLink :to="localePath('/terms-and-conditions')">{{ $t('footer.terms') }}</NuxtLink>
      <a href="https://www.facebook.com/profile.php?id=100085010487813" target="_blank">
        <div class="icon">
          <i class="fa-brands fa-facebook-f"></i>
        </div>
      </a>
      <a href="https://www.instagram.com/worksnap_/" target="_blank">
        <div class="icon">
          <i class="fa-brands fa-instagram"></i>
        </div>
      </a>
      <a href="https://www.linkedin.com/company/worksnap-international/" target="_blank">
        <div class="icon">
          <i class="fa-brands fa-linkedin"></i>
        </div>
      </a>
    </nav>
  </footer>

  <AuthModal v-model:is-open="isAuthModalOpen" />

</template>

<style lang="postcss" scoped>
@import "open-props/media";

footer {
  color: var(--text-color-primary-inverted);
  background-color: var(--background-color-footer);
  padding-block-end: 3rem;

  & section {
    display: flex;
    padding-block: var(--container-block-padding);
    padding-bottom: 4rem;
    flex-wrap: wrap;
    gap: var(--container-block-padding);

    & address {
      font-style: normal;
      margin-right: auto;

      & p {
        max-width: 500px;
      }

      & h2 {
        margin: 0;
        font-size: 2.6rem;
        color: var(--grey-color);

        & .green {
          color: var(--primary-color);
        }
      }

      & {
        text-wrap: balance;
        white-space: pre-wrap;
      }

      & ul {
        list-style: none;
        padding: 0;
        margin-top: 3rem;

        & li {
          display: grid;
          grid-template-columns: 2rem 1fr;
          align-items: center;
          color: var(--text-color-primary-inverted);
          gap: 1rem;
          margin-block: 1.5rem;

          & .icon {
            display: flex;
            justify-content: center;
            color: var(--primary-color);
            font-size: 2rem;
          }

          & a {
            color: var(--text-color-primary-inverted);
            text-decoration: none;
          }
        }
      }
    }

    & nav {
      & h3 {
        margin: 0;
        font-size: 2.1rem;
        color: var(--text-color-inverted);
      }

      & ul {
        padding: 0;
        list-style: none;

        & li {
          margin: 0;

          & a {
            display: inline-block;
            text-decoration: none;
            padding-block: 1rem;
            color: var(--text-color-secondary-inverted);
          }
        }
      }
    }
  }

  & > nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--container-gap);
    flex-wrap: wrap;

    & p {
      margin: 0;
      margin-right: auto;
      white-space: pre-wrap;

      & strong {
        font-size: inherit;
      }
    }

    & a {
      text-decoration: none;
      color: var(--text-color-secondary-inverted);

      & .icon {
        display: flex;
        justify-content: center;
        color: var(--primary-color);
        font-size: 2rem;
      }
    }

    &.partners {
      margin-bottom: 1.5em;
      @media (--md-n-below) {
        display: none;
      }

      & ul {
        list-style: none;
        padding: 0;
        display: flex;
        vertical-align: middle;

        & li {
          flex-direction: column;
          margin-right: 1em;

          & img {
            max-height: 30px;
            filter: grayscale(1) invert(1) opacity(90%);
          }
        }
      }
    }
  }
}
</style>
